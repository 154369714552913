<template>
  <div>
    <div class="field">
      <div class="control">
        <input class="is-checkradio"
          id="create"
          type="radio"
          name="create"
          :value="true"
          v-model="innerValue.isCreateQuote">
        <label for="create">Create new quote</label>
      </div>
    </div>
    <div
      class="field">
      <div class="control">
        <input class="is-checkradio"
          id="assign"
          type="radio"
          name="assign"
          :value="false"
          v-model="innerValue.isCreateQuote">
        <label for="assign">Assign quote</label>
      </div>
    </div>
    <div v-if="(ecomType === 'orm' || ecomType === 'audanet') && !innerValue.isCreateQuote">
      <load-quote-assign
        v-model="value"
        :rego-no="value.regoNo"
        :ecom-type="ecomType" />
    </div>
    <div v-if="ecomType === 'pnet' && !innerValue.isCreateQuote">
      <div class="control">
        <input class="input is-uppercase"
          :class="{ 'is-danger' : !validQuoteNo }"
          v-model="quoteNo"
          @input="searchQuote"
          placeholder="Quote No."
          v-focus-inserted>
      </div>
      <span v-if="validQuoteNo && $v.loadOptions.quoteId.$dirty"
        class="help is-success">Valid Quote No.</span>
      <span v-if="!validQuoteNo"
        class="has-text-danger">
        <span class="icon has-text-danger">
          <i class="mdi mdi-alert-octagram mdi-24px" />
        </span>
        Please enter an existing Quote No.
      </span>
    </div>
  </div>
</template>

<script>
import Guid from '@/components/Guid'
import _debounce from 'lodash.debounce'
import LoadQuoteService from './LoadQuoteService'
import LoadQuoteAssign from './LoadQuoteAssign'
import { FocusInserted } from '@/components/directives'

export default {
  name: 'LoadQuoteCreateStep',
  inject: ['$vv'],
  components: {
    LoadQuoteAssign
  },
  directives: {
    FocusInserted
  },
  props: {
    value: null,
    ecomType: {
      type: String
    }
  },
  data() {
    return {
      quoteNo: '',
      innerValue: this.value
    }
  },
  computed: {
    validQuoteNo() {
      return !this.$v.loadOptions.quoteId.$error || this.value.isCreateQuote
    },
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {
    searchQuote: _debounce(async function () {
      this.$v.loadOptions.quoteId.$touch()
      if (this.quoteNo) {
        this.innerValue.quoteId = await LoadQuoteService.getQuoteIdByNo(this.quoteNo)
      } else {
        this.innerValue.quoteId = Guid.empty()
      }
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';

input.is-uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}
</style>
